import Roles from "@/utils/roles"

export default [
  {
    path: "/envio-emails",
    name: "envio-emails",
    component: () => import("@/views/area-mention/envio-emails/index.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/editar/:id",
    name: "editar-email",
    component: () => import("@/views/area-mention/envio-emails/form.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/editar-regua/:id",
    name: "editar-regua",
    component: () => import("@/views/area-mention/envio-emails/form-regua.vue"),
    meta: {
      title: 'Régua de comunicação',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/novo-email",
    name: "novo-email",
    component: () => import("@/views/area-mention/envio-emails/form.vue"),
    meta: {
      title: 'Envio de e-mails',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },

  {
    path: "/envio-emails/novo-email-educativo",
    name: "novo-email-educativo",
    component: () => import("@/views/area-mention/envio-emails/form-educativo.vue"),
    meta: {
      title: 'E-mails educativos',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
  {
    path: "/envio-emails/editar-email-educativo/:id",
    name: "editar-email-educativo",
    component: () => import("@/views/area-mention/envio-emails/form-educativo.vue"),
    meta: {
      title: 'E-mails educativos',
      authRequired: true,
      roles: [Roles.EnvioEmails]
    }
  },
];